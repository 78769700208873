import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "./store";
import api from "./api";
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || "ru",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
	messages: {},
});

export async function initializeI18n() {
	try {
		let selectedLanguage = localStorage.getItem("selectedLanguage");

		const languages = await api.ksk.load_languages();
		store.commit("locales/SET_LANGUAGES", languages);

		languages.forEach((lang) => {
			if (!i18n.availableLocales.includes(lang.locale)) {
				i18n.setLocaleMessage(lang.locale, {});
			}
		});

		if (!selectedLanguage) {
			const browserLang = navigator.language || navigator.languages[0];
			console.log("Browser language detected:", browserLang);

			const matchedLanguage = languages.find(
				(lang) => lang.locale === browserLang || browserLang.startsWith(lang.locale)
			);
			selectedLanguage = matchedLanguage ? matchedLanguage.locale : "ru";
		}

		i18n.locale = selectedLanguage;

		const translations = await api.ksk.load_translations(selectedLanguage);
		i18n.setLocaleMessage(selectedLanguage, translations);

		localStorage.setItem("selectedLanguage", selectedLanguage);
	} catch (error) {
		console.error("Ошибка инициализации i18n:", error);
	}
}

export async function changeLanguage(locale) {
	if (!i18n.availableLocales.includes(locale)) {
		console.warn(`Язык ${locale} не поддерживается.`);
		return;
	}
	try {
		const translations = await api.ksk.load_translations(locale);
		i18n.setLocaleMessage(locale, translations);
		i18n.locale = locale;
	} catch (error) {
		console.error(`Ошибка при переключении языка на ${locale}:`, error);
	}
}

export default i18n;
