import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/style.scss";
import '@mdi/font/css/materialdesignicons.css';
// import { initializeApp } from 'firebase/app';
// import { getMessaging } from 'firebase/messaging';

// Plugins
import vuetify from "./plugins/vuetify";
import api from "./plugins/api";
import i18n, { initializeI18n } from "./i18n";
import VueApexCharts from "vue-apexcharts";

// Directives
import { VueMaskDirective } from "v-mask";

// Helpers
import "@/helpers/filters";
import "@/helpers/defineProperties";

// Global mixin
import "./mixins/globalMixin";

// Global components
import AlertText from "@/components/app/AlertText";
import Autocomplete from "@/components/app/Autocomplete";
import Button from "@/components/app/Button";
import Label from "@/components/app/Label";
import Modal from "@/components/app/Modal";
import TextBox from "@/components/app/TextBox";
import TextField from "@/components/app/TextField";
import Select from "@/components/app/Select";

Vue.component("AlertText", AlertText);
Vue.component("Autocomplete", Autocomplete);
Vue.component("Button", Button);
Vue.component("Label", Label);
Vue.component("Modal", Modal);
Vue.component("TextBox", TextBox);
Vue.component("TextField", TextField);
Vue.component("Select", Select);

Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);

Vue.use(api);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// const firebaseConfig = { 
//   apiKey: "AIzaSyApKShjKB2DBa0MuU4xCUEu7sIEt1IS8a8", 
//   authDomain: "eksk-api.firebaseapp.com", 
//   databaseURL: "https://eksk-api.firebaseio.com", 
//   projectId: "eksk-api", 
//   storageBucket: "eksk-api.appspot.com", 
//   messagingSenderId: "627477012100", 
//   appId: "1:627477012100:web:bec18216df65eaf9ee6cd1", 
//   measurementId: "G-L1VS18L2NK" 
// };

// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// console.log('messaging', messaging)

(async () => {
	try {
		await initializeI18n();
		new Vue({
				router,
				store,
				vuetify,
				i18n,
				render: h => h(App),
		}).$mount("#app");
	} catch (error) {
			console.error("Ошибка инициализации приложения:", error);
	}
})();
